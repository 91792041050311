/** @format */

import { put, takeLatest } from "redux-saga/effects";

function* saveUserData(action) {
  const userData = action.payload;
  yield put({ type: "SET_USER_DATA", payload: userData });
}

export function* watchUserData() {
  yield takeLatest("SAVE_USER_DATA_REQUEST", saveUserData);
}
