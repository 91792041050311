/** @format */

import React, { useState } from "react";

const Tabs = ({ tabs, handleTabChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleIndexChange = (index) => {
    setActiveIndex(index);
    handleTabChange(index);
  };
  return (
    <>
      <div>
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              onClick={() => handleIndexChange(index)}
              className={`w-1/2 border-b border-gray-100 py-3 sm:text-xl font-medium text-base   ${
                index === activeIndex ? "bg-teal text-white rounded-xl" : ""
              }`}
            >
              {tab}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
