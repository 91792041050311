/** @format */
import {
  FETCH_LETTERS_REQUEST,
  FETCH_LETTERS_SUCCESS,
  FETCH_LETTERS_FAILURE,
  FETCH_LETTER_REQUEST,
  FETCH_LETTER_SUCCESS,
  FETCH_LETTER_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  FETCH_LETTERS_BY_CATEGORY_REQUEST,
  FETCH_LETTERS_BY_CATEGORY_SUCCESS,
  FETCH_LETTERS_BY_CATEGORY_FAILURE,
  FETCH_LETTERS_BY_SEARCH_REQUEST,
  FETCH_LETTERS_BY_SEARCH_SUCCESS,
  FETCH_LETTERS_BY_SEARCH_FAILURE,
  PROCESS_PAYMENT_REQUEST,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAILURE,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAILURE,
} from "../actions/medicalLetterActions";

const initialState = {
  loading: false,
  loadingLetters: false,
  loadingSearch: false,
  medicalLetters: null,
  currentLetter: null,
  error: null,
  checkoutData: null,
  searchLetters: null,
  categoryLetters: null,
  paymentData: null,
  paymentStatus: null,
};

const medicalLetterReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * -------------------------------
     * Fetch Multiple Medical Letters
     * -------------------------------
     */
    case FETCH_LETTERS_REQUEST:
      return {
        ...state,
        loadingLetters: true,
        error: null,
      };
    case FETCH_LETTERS_SUCCESS:
      return {
        ...state,
        loadingLetters: false,
        medicalLetters: action.payload?.data,
      };
    case FETCH_LETTERS_FAILURE:
      return {
        ...state,
        loadingLetters: false,
        error: action.payload,
      };

    /**
     * -------------------------------
     * Fetch Single Medical Letter
     * -------------------------------
     */
    case FETCH_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentLetter: action.payload?.data,
      };
    case FETCH_LETTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /**
     * -------------------------------
     * For The Checkout
     * -------------------------------
     */
    case CHECKOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutData: action.payload,
      };
    case CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /**
     * -------------------------------
     * Fetch Letters by Category
     * -------------------------------
     */
    case FETCH_LETTERS_BY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LETTERS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryLetters: action.payload?.data,
      };
    case FETCH_LETTERS_BY_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /**
     * -------------------------------
     * Fetch Letters by Search
     * -------------------------------
     */
    case FETCH_LETTERS_BY_SEARCH_REQUEST:
      return {
        ...state,
        loadingSearch: true,
        error: null,
      };
    case FETCH_LETTERS_BY_SEARCH_SUCCESS:
      return {
        ...state,
        loadingSearch: false,
        searchLetters: action.payload?.data,
      };
    case FETCH_LETTERS_BY_SEARCH_FAILURE:
      return {
        ...state,
        loadingSearch: false,
        error: action.payload,
      };

    /**
     * -------------------------------
     * Process Payment
     * -------------------------------
     */
    case PROCESS_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentData: action.payload,
      };
    case PROCESS_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /* * -------------------------------
     * Handle Payment Status
     * -------------------------------
     */
    case PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatus: action.payload,
      };
    case PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default medicalLetterReducer;
