/** @format */
import React from "react";
import ImageGallery from "./ImageGallery";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const ProductDetailsCard = ({ letter, loading }) => {
  const navigate = useNavigate();

  const handleNavigateCheckout = (letterId) => {
    navigate(`/product-detail/${letterId}/product-summary/checkout`);
  };

  return (
    <>
      {loading ? (
        <div className=" sm:mx-2 lg:mx-8 xl:mx-0 sm:p-8 p-6 bg-white xl:shadow-lg rounded-lg flex flex-col sm:flex-row gap-10">
          <div className="w-full sm:w-2/5">
            <div role="status" className="p-4 rounded animate-pulse">
              <div className="flex items-center justify-center h-96 sm:h-auto w-full max-w-full md:h-[480px] bg-gray-100 rounded dark:bg-gray-300">
                <svg
                  className="w-10 h-10 text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
            </div>

            <div className="flex gap-4 mt-4 justify-center animate-pulse w-full">
              {[...Array(4)].map((_, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center h-20 w-40 cursor-pointer rounded-lg object-cover object-center hover:scale-110 sm:h-36 bg-gray-100 dark:bg-gray-300"
                >
                  <svg
                    className="w-10 h-10 text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full sm:w-2/4 ">
            <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
              <div className="flex flex-col gap-12 mt-6 sm:mt-20 items-start w-full sm:gap-12 xl:gap-24">
                <div className="h-14 bg-gray-100 rounded-lg dark:bg-gray-300 w-16"></div>
                <div>
                  <div className="  ">
                    <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 w-svw max-w-xs lg:max-w-sm"></div>
                    <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 w-svw max-w-xs lg:max-w-sm mt-2"></div>
                  </div>
                  <div>
                    <div className="border-2 border-gray-200 w-72 mt-2"></div>
                    <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-10"></div>
                    <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-2"></div>
                  </div>
                </div>
                <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 w-56"></div>
                <div className="h-14 bg-gray-100 rounded-lg dark:bg-gray-300 w-48"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" mx-8 xl:mx-0 sm:p-8 p-6 xl:bg-white xl:shadow-lg  rounded-lg flex sm:flex-row flex-col sm:gap-10">
          {" "}
          <div className="xl:w-2/4  ">
            <ImageGallery />
          </div>
          <div className=" flex flex-col gap-9 sm:gap-5 xl:gap-9 my-16">
            <div className="flex sm:flex-col  gap-9 justify-between items-center sm:items-start">
              <div className=" lg:text-6xl sm:text-5xl text-2xl text-left font-semibold   ">
                £{letter?.price}
              </div>
            </div>
            <div className="  sm:w-full">
              <div className="text-teal lg:text-4xl  sm:text-3xl text-2xl font-bold text-left text-wrap h-20 line-clamp-2 pt-2">
                {letter?.name}
              </div>

              <div className="border-t border-teal mt-2 mb-5"></div>
              <div className="text-left text-wrap line-clamp-4 lg:text-lg sm:text-base text-sm">
                <span className="font-bold  capitalize">Same-day </span>
                {letter?.description}
              </div>
            </div>
            <div className="flex sm:flex-col sm:gap-9 flex-row justify-between sm:justify-start sm:items-start items-center w-64 sm:w-full sm:pt-2 xl:pt-0">
              <div className="flex flex-col sm:flex-col-reverse md:gap-9 gap-2 sm:mt-32">
                <div className="flex ">
                  <Button
                    buttonText="Buy Now"
                    buttonStyle="bg-teal text-white sm:px-16 sm:py-4 px-12 py-3 text-lg rounded-xl cursor-pointer"
                    onButtonClick={() => handleNavigateCheckout(letter.id)}
                  />
                </div>
                <div>
                  <div className="text-left  text-textGray text-xs  xl:text-lg sm:text-base">
                    Availability: Deliverable Immediately
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetailsCard;
