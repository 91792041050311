/** @format */

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GeneralLettersCard from "../../components/GeneralLettersCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchLettersBySearchRequest } from "../../store/actions/medicalLetterActions";

const SearchResult = () => {
  const { searchQuery } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLettersBySearchRequest(searchQuery));
  }, [searchQuery, dispatch]);

  const searchResult = useSelector(
    (state) => state?.medicalLetter?.searchLetters
  );
  const loadingSearchResult = useSelector(
    (state) => state?.medicalLetter?.loading
  );

  const placeholderArray = new Array(10).fill(null);

  return (
    <>
      <div className="py-16">
        <div className="sm:text-4xl text-left sm:px-32 text-2xl px-4">
          Showing results for{" "}
          <span className="font-semibold">"{searchQuery}"</span>
        </div>
        {loadingSearchResult ? (
          <div className="animate-pulse flex sm:flex-row flex-col gap-10 justify-center items-center py-10 sm:flex-wrap">
            {placeholderArray.map((_, index) => (
              <div
                key={index}
                className="xl:w-1/6 lg:w-1/4 sm:w-1/3 w-80 shadow-lg p-4 rounded-xl bg-white"
              >
                <div
                  role="status"
                  className="space-y-2.5 animate-pulse max-w-lg"
                >
                  <div className="flex flex-col gap-8">
                    <div className="h-10 bg-gray-100 rounded-lg dark:bg-gray-300 w-12"></div>
                    <div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 max-w-xs lg:max-w-sm"></div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 max-w-xs lg:max-w-sm mt-2"></div>
                    </div>
                    <div>
                      <div className="border-2 border-gray-200 w-full mt-2"></div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-6"></div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-2"></div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-2"></div>
                      <div className="h-4 bg-gray-100 rounded-full dark:bg-gray-300 sm:max-w-xs lg:max-w-sm mt-2"></div>
                    </div>
                    <div className="h-12 bg-gray-100 rounded-lg dark:bg-gray-300 w-24"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : searchQuery ? (
          <div>
            {searchResult?.length > 0 ? (
              <GeneralLettersCard letters={searchResult} />
            ) : (
              <div className="text-xl text-left h-screen flex justify-center items-start my-10">
                Sorry! No Result Found
              </div>
            )}
          </div>
        ) : (
          <div className="text-4xl text-left">No search query provided</div>
        )}
      </div>
    </>
  );
};

export default SearchResult;
