/** @format */

import React from "react";

const TopHeader = () => {
  return (
    <header className="h-11 hidden xl:block">
      <div className="flex justify-between items-center text-black text-sm h-11">
        <div className="flex-1 content-center bg-[#4BE8DD] h-11">
          <p className="font-light">No Appointment Required</p>
        </div>
        <div className="flex-1 content-center bg-[#77F6ED] h-11">
          <p>Same-day service 9AM - 9PM</p>
        </div>
        <div className="flex-1 content-center bg-[#9AFFF8] text-center h-11">
          <p>GMC registered doctors</p>
        </div>
        <div className="flex-1 content-center bg-[#CAFFFB] text-center h-11">
          <p>CQC Required</p>
        </div>
      </div>
    </header>
  );
};

export default TopHeader;
