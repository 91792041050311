/** @format */

import React, { useRef } from "react";
import HeroSection from "../../components/HeroSection";
import VerificationSteps from "../../components/VerificationSteps";
import GeneralLetters from "../../components/GeneralLetters";
import Testimonials from "../../components/Testimonials";
// import { Link } from 'react-router-dom';

const RecoveryCertificates = () => {
  const medicalLettersRef = useRef();
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow text-black  py-8">
        <div className="xl:px-32 px-8 pb-16 ">
          <HeroSection
            scrollElement={medicalLettersRef}
            letterTitle={"Recovery\nCertificates "}
            bannerImage="/assets/recovery_section.png"
          />
          <VerificationSteps />
        </div>
        <div ref={medicalLettersRef}>
          <GeneralLetters />
        </div>

        <div className="md:px-16 lg:px-32 px-8 pb-16 ">
          <Testimonials />
        </div>
      </main>
    </div>
  );
};

export default RecoveryCertificates;
