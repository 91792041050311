/** @format */

import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./axios.js";
import Main from "./pages/Main.js";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Main />
    </BrowserRouter>
  );
}

export default App;
