/** @format */

import React from "react";

const CheckMarkCard = ({ items, isSummary }) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={`bg-white p-4   flex items-center h-24  ${
              isSummary
                ? "w-full shadow-md shadow-gray-200/50"
                : "sm:w-80 xl:w-1/4 w-full drop-shadow-lg"
            } rounded-lg gap-3`}
          >
            <img src="/assets/Checkmark.svg" alt="checkmark" className="w-12" />

            <div
              className={`text-left text-textGray ${
                isSummary ? "text-base" : "xl:text-sm text-xs"
              }  w-full text-wrap`}
            >
              {item}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CheckMarkCard;
