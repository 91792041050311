/** @format */

import React from "react";
import SendUsMessageForm from "./SendUsMessageForm";

const SendUsMessage = () => {
  return (
    <>
      <div className="sm:my-10 my-2 rounded-lg flex flex-col md:flex-row shadow-xl p-4 h-full ">
        <div className="bg-teal hidden w-full md:w-2/4 rounded-lg text-white pl-4 md:pl-10 pt-8 sm:flex flex-col justify-between ">
          <div className="py-4">
            <div className="text-2xl sm:text-3xl text-left font-semibold">
              Contact Information
            </div>
            <div className="text-lg sm:text-xl font-thin py-2 text-left ">
              Drop us your query & we’ll get back to you.
            </div>
          </div>
          <div className="flex flex-col justify-between h-44 font-thin">
            <div className="flex items-center">
              <span className="material-icons w-12 ">
                phone_in_talk_outlined
              </span>
              +1012 3456 789
            </div>
            <div className="flex items-center">
              <span className="material-icons w-12 ">email_outlined</span>
              demo@gmail.com
            </div>
            <div className="flex items-center w-full sm:w-2/3 text-left">
              <span className="material-icons w-12 ">fmd_good_outlined</span>
              132 Dartmouth Street Boston, Massachusetts 02156 United States
            </div>
          </div>
          <div className="flex justify-end items-end">
            <img src="/assets/round_circle.svg" alt="circle" />
          </div>
        </div>
        <div className="w-full md:w-2/3 mt-8 md:mt-0 border borer-gray-200 sm:border-0 rounded-xl">
          <SendUsMessageForm />
        </div>
      </div>
      <div className="flex sm:hidden flex-col justify-between h-72 font-thin bg-teal text-white p-8 mx-4 mt-24 rounded-xl">
        <div className="flex flex-col items-center text-center">
          <span className="material-icons w-12 ">phone_in_talk_outlined</span>
          +1012 3456 789
        </div>
        <div className="flex flex-col items-center text-center">
          <span className="material-icons w-12 ">email_outlined</span>
          demo@gmail.com
        </div>
        <div className="flex flex-col text-center items-center w-full sm:w-2/3 ">
          <span className="material-icons w-12 ">fmd_good_outlined</span>
          132 Dartmouth Street Boston, Massachusetts 02156 United States
        </div>
      </div>
    </>
  );
};

export default SendUsMessage;
