/** @format */

import React, { useState } from "react";

const ImageGallery = () => {
  const data = [
    {
      imgelink:
        "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    },
    {
      imgelink:
        "https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    },
    {
      imgelink:
        "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
    },
    {
      imgelink:
        "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="grid gap-4 relative">
      <div className="opacity-100">
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px] transition-transform duration-500 ease-in-out"
          src={data[activeIndex].imgelink}
          alt=""
        />
        <div className="absolute top-24 left-4 transform -translate-y-1/2 md:top-64">
          <button
            onClick={handlePrev}
            className="bg-teal text-white  pl-2 pr-1.5 py-1 rounded-md flex items-center bg-opacity-90"
          >
            <span className="material-icons w-4 text-lg">
              arrow_back_ios_outlined
            </span>
          </button>
        </div>

        <div className="absolute top-24 right-4 transform -translate-y-1/2  md:top-64">
          <button
            onClick={handleNext}
            className="bg-teal text-white  px-2 py-1 rounded-md flex items-center bg-opacity-90"
          >
            <span className="material-icons w-4 text-lg ">
              arrow_forward_ios_outlined
            </span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4  gap-4">
        {data.map((image, index) => (
          <div key={index} className="">
            <img
              onClick={() => setActiveIndex(index)}
              src={image.imgelink}
              className={`h-20 w-full cursor-pointer rounded-lg object-cover object-center  hover:scale-110 sm:h-36
              ${activeIndex === index ? "border-4 border-teal" : ""}`}
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
