import React from 'react';
// import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    return (
        <div className=" text-black h-screen pt-24">
            <div className="text-5xl font-semibold">
                Terms & Conditions
            </div>
            <div className='pt-5 font-light text-base text-gray-500'>
                You can find the answers to all your questions below
            </div>
        </div>
    );
};

export default TermsAndConditions;