/** @format */

import React, { useState, useEffect } from "react";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  paymentStatusRequest,
  processPaymentRequest,
} from "../store/actions/medicalLetterActions";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const StripeCardElement = ({
  handleButtonClick,
  userName,
  userEmail,
  letterId,
  handleCheckout,
  checkoutSuccess,
}) => {
  const [error, setError] = useState("");
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.medicalLetter?.paymentData);
  const loading = useSelector((state) => state?.medicalLetter?.loading);
  const errorProcessing = useSelector((state) => state?.medicalLetter?.error);
  const paymentRequestId = useSelector(
    (state) => state.medicalLetter?.checkoutData?.data?.patient_details?.id
  );

  const paymentStatus = useSelector(
    (state) => state.medicalLetter?.paymentStatus
  );
  useEffect(() => {
    if (paymentStatus?.success) {
      navigate(
        `/product-detail/${letterId}/product-summary/checkout/thank-you/${paymentStatus?.data.tracking_id}`
      );
    }
  }, [paymentStatus, navigate, letterId]);

  useEffect(() => {
    if (checkoutSuccess) {
      dispatch(
        processPaymentRequest({
          patient_name: userName,
          patient_email: userEmail,
          medical_letter: letterId,
        })
      );
    }
  }, [
    checkoutSuccess,
    handleCheckout,
    dispatch,
    userName,
    userEmail,
    letterId,
  ]);

  useEffect(() => {
    if (payment?.clientSecret) {
      confirmPayment(payment?.clientSecret, payment?.payment_id, letterId);
    }
  }, [payment?.clientSecret, payment?.payment_id]);

  const handleBackButtonClick = () => {
    handleButtonClick(3);
  };

  const handleNextButtonClick = () => {
    handleCheckout();
  };

  const confirmPayment = async (clientSecret, paymentId, letterId) => {
    if (!stripe || !elements || !payment?.clientSecret) return;

    const cardElement = elements.getElement(CardNumberElement);

    try {
      const { error: paymentError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: userName,
              email: userEmail,
            },
          },
        });
      // stripe.cancel(paymentIntent);

      if (paymentError) {
        setError(paymentError.message);
        const formData = new FormData();
        formData.append("medical_letter_request", paymentRequestId);
        formData.append("payment_status", "incomplete");
        formData.append("payment_id", paymentId);

        dispatch(paymentStatusRequest(formData));
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        const formData = new FormData();
        formData.append("medical_letter_request", paymentRequestId);
        formData.append("payment_status", "completed");
        formData.append("payment_id", paymentId);

        dispatch(paymentStatusRequest(formData));
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="px-6 py-12">
      <div className="pb-5">
        <h2 className="sm:text-4xl text-3xl font-bold text-teal sm:text-left text-center">
          Payment <span className="hidden sm:inline-block">Via Card</span>
        </h2>
      </div>
      <div className="my-8">
        <div className="mb-4 flex flex-col">
          <label className="text-left mb-2">Card Number</label>
          <div className="border border-gray-300 rounded-lg p-4 bg-white">
            <CardNumberElement />
          </div>
        </div>
        <div className="flex sm:flex-row flex-col gap-4">
          <div className="mb-4 flex flex-col sm:w-1/2">
            <label className="text-left mb-2">Expiry Date</label>
            <div className="border border-gray-300 rounded-lg p-4 bg-white">
              <CardExpiryElement />
            </div>
          </div>
          <div className="mb-4 flex flex-col sm:w-1/2">
            <label className="text-left mb-2">Security Code</label>
            <div className="border border-gray-300 rounded-lg p-4 bg-white">
              <CardCvcElement />
            </div>
          </div>
        </div>
        {error ? (
          <div className="text-left text-red-500 text-xs font-medium">
            {error}
          </div>
        ) : errorProcessing ? (
          <div className="text-left text-red-500 text-xs font-medium">
            We ran into a problem please try again later.
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-10 flex sm:flex-row flex-col-reverse w-full gap-2">
        <Button
          buttonStyle={
            "py-3 bg-white text-teal border-2 border-teal rounded-xl cursor-pointer sm:w-1/2"
          }
          buttonText="Back"
          onButtonClick={handleBackButtonClick}
        />
        <Button
          buttonStyle={
            "py-3 bg-teal rounded-xl text-white cursor-pointer sm:w-1/2"
          }
          buttonText="Pay"
          onButtonClick={handleNextButtonClick}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default StripeCardElement;
