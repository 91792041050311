/** @format */

import { combineReducers } from "redux";
import userReducer from "./userReducer";
import medicalLetterReducer from "./medicalLetterReducer";

const rootReducer = combineReducers({
  user: userReducer,
  medicalLetter: medicalLetterReducer,
});

export default rootReducer;
