/** @format */

import React, { useEffect } from "react";
import GeneralLettersCard from "./GeneralLettersCard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLettersByCategoryRequest } from "../store/actions/medicalLetterActions";

const GeneralLetters = () => {
  const location = useLocation();

  const getRouteId = () => {
    switch (location.pathname) {
      case "/":
        return 1;
      case "/recovery-certificates":
        return 2;
      case "/sickness-letters":
        return 3;
      case "/travel-and-holiday":
        return 4;
      case "/visa-medicals":
        return 5;
      default:
        return null;
    }
  };
  const categoryId = getRouteId();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLettersByCategoryRequest(categoryId));
  }, [dispatch, categoryId]);

  const letters = useSelector(
    (state) => state.medicalLetter?.categoryLetters?.medical_letters
  );

  return (
    <>
      <div className="py-12 bg-teal bg-opacity-5">
        <div className="text-center font-semibold sm:text-4xl text-2xl">
          Medical General Letters
        </div>
        <div className="text-center text-teal sm:text-base text-sm font-light mt-1 px-6">
          General medical letters to access private specialist care.
        </div>
        <GeneralLettersCard letters={letters} />
      </div>
    </>
  );
};

export default GeneralLetters;
