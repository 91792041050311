/** @format */

import React, { useState } from "react";

const Select = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full text-left">
      <div
        className={`w-full p-2 border rounded-lg  flex justify-between items-center  cursor-pointer transition-all duration-300 ease-in-out focus:outline-none ${
          isOpen ? "border-blue-500" : "border-gray-300"
        } bg-white`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value ? value.label : "Select an option"}
        {isOpen ? (
          <div className="material-icons w-5 focus:outline-none">
            keyboard_arrow_up_outlined
          </div>
        ) : (
          <div className="material-icons w-5">keyboard_arrow_down_outlined</div>
        )}
      </div>
      {isOpen && (
        <ul className="absolute top-full left-0 right-0 mt-1 bg-white border border-gray-300 rounded-lg max-h-52 overflow-y-auto z-10">
          {options.map((option) => (
            <li
              key={option.value}
              className="p-2 cursor-pointer hover:bg-teal hover:text-white transition-colors duration-200 justify-between"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Select;
