/** @format */

import AppRoutes from "../routes/AppRoutes";
import TopHeader from "../components/TopHeader";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchLettersRequest } from "../store/actions/medicalLetterActions";

const Main = () => {
  const letters = useSelector((state) => state.medicalLetter?.medicalLetters);
  const loading = useSelector((state) => state.medicalLetter?.loadingLetters);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLettersRequest());
  }, [dispatch]);
  return (
    <div className="App overflow-x-hidden">
      <TopHeader />
      <NavHeader letters={letters} isLoading={loading} />
      <div>
        <AppRoutes />
      </div>
      <Footer letters={letters} />
    </div>
  );
};

export default Main;
