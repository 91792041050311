/** @format */

import React, { useState } from "react";

const FAQsList = ({ FAQs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="flex flex-col text-left px-4 sm:px-8 md:px-20 lg:px-60 py-12 sm:py-24">
      {FAQs.map((FAQ, index) => (
        <div key={index} className="mb-4 border-b pb-2">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
          >
            <h3 className="text-base mr-2 sm:text-lg lg:text-xl pb-2">
              {FAQ.question}
            </h3>
            <span
              className={`material-icons bg-teal rounded-full text-white transition-transform duration-300 ${
                openIndex === index ? "rotate-180" : ""
              }`}
            >
              expand_more
            </span>
          </div>
          <div
            className={`mt-2 overflow-hidden text-sm transition-all duration-300 ease-in-out ${
              openIndex === index ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <p>{FAQ.answer}</p>

            {FAQ.list && FAQ.list.length > 0 && (
              <ul className="list-none mt-2">
                {FAQ.list.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center mb-1">
                    <span className="material-icons text-gray-600 mr-1 text-sm">
                      check
                    </span>
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQsList;
