/** @format */

import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const GeneralLettersCard = ({ letters }) => {
  const navigate = useNavigate();
  const handleNavigation = (letterId) => {
    navigate(`/letter-detail/${letterId}`);
  };
  return (
    <>
      <div className="flex sm:flex-row flex-col gap-10 justify-center items-center py-10  sm:flex-wrap  ">
        {letters?.map((letter, index) => {
          return (
            <div
              key={index}
              className="bg-white  px-6 rounded-xl xl:w-1/6 lg:w-1/4 md:w-1/3 w-10/12   shadow-lg shadow-gray-200 h-full"
            >
              <div className="py-4 text-3xl text-left">£{letter.price}</div>
              <div className="text-teal text-lg font-semibold text-left text-wrap h-14 line-clamp-2 pt-1">
                {letter.name}
              </div>
              <div className="border-t border-teal my-2"></div>
              <div className="text-left text-wrap line-clamp-4">
                <span className="font-bold text-sm capitalize">Same-day </span>
                {letter.description}
              </div>
              <div className="flex pt-6 pb-4">
                <Button
                  buttonText="Buy Now"
                  buttonStyle="bg-teal text-white px-6 py-2 rounded-lg cursor-pointer"
                  onButtonClick={() => handleNavigation(letter.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default GeneralLettersCard;
