/** @format */

import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_LETTERS_REQUEST,
  fetchLettersSuccess,
  fetchLettersFailure,
  FETCH_LETTER_REQUEST,
  fetchLetterSuccess,
  fetchLetterFailure,
  CHECKOUT_REQUEST,
  checkoutSuccess,
  checkoutFailure,
  FETCH_LETTERS_BY_CATEGORY_REQUEST,
  fetchLettersByCategorySuccess,
  fetchLettersByCategoryFailure,
  FETCH_LETTERS_BY_SEARCH_REQUEST,
  fetchLettersBySearchSuccess,
  fetchLettersBySearchFailure,
  PROCESS_PAYMENT_REQUEST,
  processPaymentSuccess,
  processPaymentFailure,
  PAYMENT_STATUS_REQUEST,
  paymentStatusSuccess,
  paymentStatusFailure,
} from "../actions/medicalLetterActions";

/**
 * -------------------------------
 * Api Calls
 * -------------------------------
 */

const fetchLettersApi = () => axios.get("medical-letters");
const fetchLetterApi = (id) => axios.get(`medical-letters/letter?letter=${id}`);
const checkoutApi = (payload) =>
  axios.post("/medical-letters/medical-letter-request", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
const fetchLettersByCategoryApi = (categoryId) =>
  axios.get(`/medical-letters/category?category=${categoryId}`);
const fetchLettersBySearchApi = (search) =>
  axios.post(`medical-letters/search`, { medical_letter: search });
const processPaymentApi = (payload) =>
  axios.post("/medical-letters/process-payment", payload);

const paymentStatusApi = (payload) =>
  axios.post(`/medical-letters/payment-status`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

/**
 * -------------------------------
 * Saga for fetching all letters
 * -------------------------------
 */

function* handleFetchLetters() {
  try {
    const response = yield call(fetchLettersApi);
    yield put(fetchLettersSuccess(response.data));
  } catch (error) {
    yield put(fetchLettersFailure(error.message));
  }
}

/**
 * ---------------------------------
 * Saga for fetching a single letter
 * ---------------------------------
 */

function* handleFetchLetter(action) {
  try {
    const response = yield call(fetchLetterApi, action.payload);
    yield put(fetchLetterSuccess(response.data));
  } catch (error) {
    yield put(fetchLetterFailure(error.message));
  }
}

/**
 * -------------------------------
 * Saga for handling checkout
 * -------------------------------
 */

function* handleCheckout(action) {
  try {
    const response = yield call(checkoutApi, action.payload);
    yield put(checkoutSuccess(response.data));
  } catch (error) {
    yield put(checkoutFailure(error.message));
  }
}

/**
 * -------------------------------------
 * Saga for fetching letters by category
 * -------------------------------------
 */

function* handleFetchLettersByCategory(action) {
  try {
    const response = yield call(fetchLettersByCategoryApi, action.payload);
    yield put(fetchLettersByCategorySuccess(response.data));
  } catch (error) {
    yield put(fetchLettersByCategoryFailure(error.message));
  }
}

/**
 * ------------------------------------
 * Saga for fetching letters by search
 * ------------------------------------
 */

function* handleFetchLettersBySearch(action) {
  try {
    const response = yield call(fetchLettersBySearchApi, action.payload);
    yield put(fetchLettersBySearchSuccess(response.data));
  } catch (error) {
    yield put(fetchLettersBySearchFailure(error.message));
  }
}

/**
 * -------------------------------
 * Saga for processing payment
 * -------------------------------
 */

function* handleProcessPayment(action) {
  try {
    const response = yield call(processPaymentApi, action.payload);
    yield put(processPaymentSuccess(response.data));
  } catch (error) {
    yield put(processPaymentFailure(error.message));
  }
}

/**
 * -------------------------------
 * Saga for fetching payment status
 * -------------------------------
 */

function* handlePaymentStatus(action) {
  try {
    const response = yield call(paymentStatusApi, action.payload);

    yield put(paymentStatusSuccess(response?.data));
  } catch (error) {
    yield put(paymentStatusFailure(error.message));
  }
}

/**
 * ----------
 * Watchers
 * ----------
 */

export function* watchFetchLetters() {
  yield takeLatest(FETCH_LETTERS_REQUEST, handleFetchLetters);
}

export function* watchFetchLetter() {
  yield takeLatest(FETCH_LETTER_REQUEST, handleFetchLetter);
}

export function* watchCheckout() {
  yield takeLatest(CHECKOUT_REQUEST, handleCheckout);
}

export function* watchFetchLettersByCategory() {
  yield takeLatest(
    FETCH_LETTERS_BY_CATEGORY_REQUEST,
    handleFetchLettersByCategory
  );
}

export function* watchFetchLettersBySearch() {
  yield takeLatest(FETCH_LETTERS_BY_SEARCH_REQUEST, handleFetchLettersBySearch);
}

export function* watchProcessPayment() {
  yield takeLatest(PROCESS_PAYMENT_REQUEST, handleProcessPayment);
}

export function* watchPaymentStatus() {
  yield takeLatest(PAYMENT_STATUS_REQUEST, handlePaymentStatus);
}
