/** @format */

import React, { useState } from "react";
import CheckMarkCard from "./CheckMarkCard";
import Button from "./Button";

const Summary = ({ handleButtonClick }) => {
  const needToSupply = [
    "Your specific allergies",
    "Allergens to avoid ",
    "Emergency medications required",
  ];

  const [termsChecked, setTermsChecked] = useState(false);
  const [detailsChecked, setDetailsChecked] = useState(false);
  const [notChecked, setNotChecked] = useState(false);

  const handleNextButtonClick = (stepNumber) => {
    if (termsChecked && detailsChecked) {
      handleButtonClick(stepNumber);
    } else {
      if (!termsChecked || !detailsChecked) {
        setNotChecked(true);
      }
    }
  };

  return (
    <>
      <div className="w-full py-10 px-6">
        <div className=" pb-5">
          <h2 className="text-3xl font-bold  text-teal sm:text-left text-center  ">
            Allergy Certificate
          </h2>
          <div className="sm:text-lg text-sm   text-textGray sm:text-left text-center sm:mt-0 mt-4 ">
            This Allergy Medical Certificate will Confirm:
          </div>
        </div>
        <div className="w-full flex flex-col gap-4">
          <CheckMarkCard items={needToSupply} isSummary={true} />
        </div>
        <div className="text-left py-6  text-textGray text-lg leading-tight sm:leading-none ">
          Our doctors will review your medical information and issue your
          certificate within a few hours.
        </div>
        <div>
          <div className="flex gap-2 text-textGray items-center justify-start">
            <input
              type="checkbox"
              className="cursor-pointer "
              onChange={() => setTermsChecked(!termsChecked)}
              onInput={() => setNotChecked(false)}
            />
            <label className="text-left">
              I agree to your{" "}
              <span className=" underline cursor-pointer">
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span className=" underline cursor-pointer">
                {" "}
                Privacy Policy.
              </span>
            </label>
          </div>
          <div className="flex gap-2 text-textGray items-center justify-start mt-3">
            <input
              type="checkbox"
              className="cursor-pointer"
              onChange={() => setDetailsChecked(!detailsChecked)}
              onInput={() => setNotChecked(false)}
            />
            <label className="text-left ">
              I understand that once my payment is processed I will need to
              complete my medical details before my certificate can be issued.
            </label>
          </div>
          {notChecked ? (
            <div className="text-left text-sm px-5 pt-2 text-red">
              Please Check the checkboxes
            </div>
          ) : (
            ""
          )}

          <div className="mt-10">
            <Button
              buttonStyle={"py-3 bg-teal rounded-xl text-white cursor-pointer"}
              buttonText="Next"
              onButtonClick={() => handleNextButtonClick(2)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
