/** @format */

import React, { useState, useEffect } from "react";

import Summary from "../../components/Summary";
import PatientDetailsForm from "../../components/PatientDetailsForm";
import MedicalDetailsForm from "../../components/MedicalDetailsForm";
import StripeCardElement from "../../components/StripeCardElement";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkoutRequest,
  fetchLetterRequest,
} from "../../store/actions/medicalLetterActions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const Checkout = () => {
  const { letterId } = useParams();
  const dispatch = useDispatch();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    dispatch(fetchLetterRequest(letterId));
  }, [dispatch, letterId]);

  const letter = useSelector((state) => state?.medicalLetter.currentLetter);
  const user = useSelector((state) => state?.user);
  const checkoutData = useSelector(
    (state) => state.medicalLetter?.checkoutData
  );

  const userName = user?.firstName + " " + user?.lastName;
  const userEmail = user?.emailAddress;
  const [step, setStep] = useState(1);
  const [checkoutDetails, setCheckoutDetails] = useState(new FormData());
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  useEffect(() => {
    if (checkoutData?.success) {
      setCheckoutSuccess(true);
    }
  }, [checkoutData]);

  const handleButtonClick = (stepNumber, userInfo, file) => {
    setStep(stepNumber);
    if (userInfo) {
      checkoutDetails.append("image", file);

      dispatch({ type: "SAVE_USER_DATA_REQUEST", payload: userInfo });
    }
  };
  const handleMedicalEvidence = (fileData) => {
    const updatedDetails = new FormData();
    updatedDetails.append("image", checkoutDetails?.get("image"));
    updatedDetails.append("medical_letter", letterId);
    updatedDetails.append("first_name", user?.firstName);
    updatedDetails.append("last_name", user?.lastName);
    updatedDetails.append("address", user?.address);
    updatedDetails.append("city", user?.city);
    updatedDetails.append("country", user?.country);
    updatedDetails.append("dob", user?.dateOfBirth);
    updatedDetails.append("gender", user?.gender);
    updatedDetails.append("up_to_date", user?.marketingConsent ? 1 : 0);
    updatedDetails.append("phone", user?.phoneNumber);
    updatedDetails.append("zipcode", user?.zipPostalCode);
    updatedDetails.append("email", userEmail);

    for (let [key, value] of fileData.entries()) {
      if (value instanceof FileList) {
        Array.from(value).forEach((file, index) => {
          updatedDetails.append(`${key}[${index}].file`, file);
        });
      } else {
        updatedDetails.append(key, value);
      }
    }

    setCheckoutDetails(updatedDetails);
  };

  const handleCheckout = () => {
    try {
      dispatch(checkoutRequest(checkoutDetails));
    } catch (error) {}
  };
  return (
    <>
      <div className="xl:mx-44 mx-6 my-20 ">
        <div className="mt-16 mb-10 xl:ml-20">
          <div className="sm:text-4xl text-2xl font-medium">
            <span className="">{step}. </span>
            {step === 1
              ? "Summary"
              : step === 2
              ? "Patient Details"
              : step === 3
              ? "Medical Details"
              : "Payment"}
          </div>

          <div className="flex  flex-col-reverse justify-center items-center my-10 gap-10">
            <div className=" w-full border border-gray-200 rounded-xl ">
              {step === 1 ? (
                <Summary handleButtonClick={handleButtonClick} />
              ) : step === 2 ? (
                <PatientDetailsForm
                  handleButtonClick={handleButtonClick}
                  user={user}
                />
              ) : step === 3 ? (
                <MedicalDetailsForm
                  handleButtonClick={handleButtonClick}
                  handleMedicalEvidence={handleMedicalEvidence}
                />
              ) : (
                <Elements stripe={stripePromise}>
                  <StripeCardElement
                    handleButtonClick={handleButtonClick}
                    letterId={letterId}
                    userName={userName}
                    userEmail={userEmail}
                    checkoutSuccess={checkoutSuccess}
                    handleCheckout={handleCheckout}
                  />
                </Elements>
              )}
            </div>
            <div className="border border-gray-200 w-full w-90  rounded-xl h-fit xl:px-12 px-8 py-10">
              <div className="xl:text-4xl md:text-3xl text-2xl font-semibold text-teal text-left pb-10 ">
                My Order Summary
              </div>
              <div className=" flex justify-between items-center">
                <div className="flex justify-center items-center gap-4 ">
                  <img
                    src="/assets/doctor_1.png"
                    className="rounded-xl sm:w-28 w-16 h-16 sm:h-full  shadow-xl"
                    alt="product"
                  />
                  <div className="text-left">
                    <div className="sm:text-base text-sm">{letter?.name}</div>
                    <div className="text-textGray sm:text-sm text-xs">
                      Deliverable Immediately
                    </div>
                  </div>
                </div>
                <div className="font-bold">£{letter?.price}.00</div>
              </div>
              <div className="pt-10 pb-6">
                <div className=" border border-dashed"></div>
              </div>
              <div className="flex justify-between">
                <div className="font-bold"> SubTotal</div>
                <div className="font-medium">£{letter?.price}.00</div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="text-textGray "> VAT (0.00% inclusive)</div>
                <div className="text-sm text-textGray">£0.00</div>
              </div>
              <div className="pt-10 pb-6">
                <div className=" border border-dashed"></div>
              </div>
              <div className="flex justify-between">
                <div className="font-bold text-xl"> Total Due</div>
                <div className="font-medium text-xl">£{letter?.price}.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
