/** @format */

import React from "react";

const PrivacyPolicy = () => {
  const policies = [
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Information related to your medical/psychiatric diagnosis and treatment may be reviewed, used, and disclosed to other professionals or care providers as necessary.",
    "You are required to grant permission for Guardia Health to share information with your GP, the NHS, and /or other specialists as needed.",
    "You are expected to provide accurate physical and mental health history and status to the best of your knowledge.",
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Guardia Health is dedicated to ensuring the privacy and security of your information.",
    "Guardia Health acknowledges and retains intellectual property rights related to any materials, assessments, or content provided during the course of its services. Unauthorized reproduction or distribution of such materials is strictly prohibited without prior written consent from Guardia Health.",
  ];

  const acknowledgement =
    "By continuing, you acknowledge and agree that you have read, understood, and accepted the terms and conditions outlined herein. Your continued use of Guardia Health constitutes your ongoing acceptance of these terms and conditions. If you do not agree with any part of these terms, please refrain from further use of Guardia Health.";

  return (
    <div className="text-black min-h-screen pt-24 px-4 sm:px-8 md:px-24">
      <div className="text-3xl sm:text-4xl md:text-5xl font-semibold">
        Privacy Policy
      </div>
      <div className="pt-5 font-light text-sm sm:text-base text-gray-500">
        You can find the answers to all your questions below
      </div>
      <div className="py-8 sm:py-16 font-normal text-sm sm:text-base text-left text-gray-600">
        <ol className=" list-inside space-y-4">
          {policies.map((policy, index) => (
            <li key={index}>
              {index + 1} - {policy}
            </li>
          ))}
        </ol>
        <div className="pt-6 text-sm sm:text-base text-gray-700">
          {acknowledgement}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
