import React from 'react';

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center text-teal-600 h-screen">
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p className="text-xl">Oops! Page not found.</p>
    </div>
  );
};

export default NotFound;
