/** @format */

import React from "react";
import { Routes, Route } from "react-router-dom";
import GeneralReferrals from "../pages/GeneralRefferals/GeneralReferrals";
import RecoveryCertificates from "../pages/RecoveryCertificates/RecoveryCertificates";
import SicknessLetters from "../pages/SicknessLetters/SicknessLetters";
import TravelAndHoliday from "../pages/TravelAndHoliday/TravelAndHoliday";
import VisaMedicals from "../pages/VisaMedicals/VisaMedicals";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import NotFound from "../pages/NotFound/NotFound";
import GeneralFAQs from "../pages/GeneralFAQs/GeneralFAQs";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import Checkout from "../pages/Checkout/Checkout";
import ThankYou from "../pages/ThankYou/ThankYou";
import SearchResult from "../pages/SearchResult/SearchResult";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<GeneralReferrals />} />
      <Route path="/recovery-certificates" element={<RecoveryCertificates />} />
      <Route path="/sickness-letters" element={<SicknessLetters />} />
      <Route path="/travel-and-holiday" element={<TravelAndHoliday />} />
      <Route path="/visa-medicals" element={<VisaMedicals />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/general-faqs" element={<GeneralFAQs />} />
      <Route path="/letter-detail/:letterId" element={<ProductDetails />} />
      <Route
        path="/product-detail/:letterId/product-summary/checkout"
        element={<Checkout />}
      />
      <Route
        path="/product-detail/:productId/product-summary/checkout/thank-you/:orderId"
        element={<ThankYou />}
      />
      <Route path="/search/:searchQuery" element={<SearchResult />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
