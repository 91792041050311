/** @format */

import React from "react";

const TestimonialCard = ({ testimonials }) => {
  return (
    <>
      <div className="flex lg:flex-row flex-col gap-10 justify-center my-4 ">
        {testimonials.map((testimonial, index) => {
          return (
            <div
              key={index}
              className="bg-white drop-shadow-xl rounded-lg   p-8 w-full"
            >
              <div className="flex justify-between pb-8 gap-4">
                <div className="flex ">
                  <div className="material-icons text-teal text-base sm:text-lg">
                    star
                  </div>
                  <div className="material-icons text-teal text-base sm:text-lg">
                    star
                  </div>
                  <div className="material-icons text-teal text-base sm:text-lg">
                    star
                  </div>
                  <div className="material-icons text-teal text-base sm:text-lg">
                    star
                  </div>
                  <div className="material-icons text-teal text-base sm:text-lg">
                    star
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex bg-teal rounded-full w-5 h-5 sm:w-6 sm:h-6 justify-center items-center">
                    <div className="material-icons text-white text-xs">
                      thumb_up
                    </div>
                  </div>
                  <div className="font-bold">Testimonial</div>
                </div>
              </div>
              <div className="text-left line-clamp-4 ">
                {testimonial.comment}
              </div>
              <div className="text-left pt-8 pb-4">
                <div className="font-bold">{testimonial.author}</div>
                <div className="text-textGray font-extralight">
                  {testimonial.designation}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TestimonialCard;
