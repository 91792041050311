/** @format */

import React from "react";
import FAQsList from "../../components/FAQsList";
import SendUsMessage from "../../components/SendUsMessage";

const GeneralFAQs = () => {
  const FAQs = [
    {
      question:
        "What will happen after I purchase an allergy medical certificate?",
      answer:
        "After uploading your medical evidence, our doctors will review your evidence on the same day (between 9am and 9pm GMT, 7 days a week) – they may call you if they have any questions.Once validated, we will send your medical certificate to you by email and SMS.",
    },
    {
      question: "What medical evidence do I require?",
      answer: "We require you to:",
      list: [
        "Upload a photo of your passport, drivers license or your childs birth certificate",
        "Complete a medical questionnaire",
        "Upload a short video (on your mobile) describing the common allergic symptoms",
      ],
    },

    {
      question: "Why are there QR codes on medical certificate?",
      answer:
        "Our medical certificates have QR codes that can be scanned to verify the documents authenticity.Once scanned, your personal online results portal is loaded, from where your medical certificate can be reviewed and personal details confirmed.",
    },

    {
      question: "Do you provide refunds?",
      answer:
        "We only provide full refunds if we are unable to provide a medical certificate.Once our doctors have provided a medical letter, we are unable to process a refund.",
    },

    {
      question: "When will I receive my medical certificate?",
      answer:
        "Our team will review your evidence and provide you with your certificate on the same day that evidence is uploaded (between 9am and 9pm GMT, 7 days a week).",
    },

    {
      question: "Who signs the allergy medical certificate?",
      answer:
        "A UK doctor at ZoomDoc Health signs your medical certificate.ZoomDoc Health are a UK-regulated healthcare service and all of our doctors are registered with the UK General Medical Council.",
    },
  ];
  return (
    <>
      <div className="py-20 xl:px-40">
        <div className="py-8">
          <div className="sm:text-5xl text-3xl font-bold">General FAQs</div>
          <div className="text-center mt-4 text-textGray sm:text-base text-sm">
            You can find the answers to all your questions below
          </div>
        </div>
        <div>
          <FAQsList FAQs={FAQs} />
        </div>
        <div className="sm:py-8 py-4">
          <div className="sm:text-5xl text-3xl font-bold">
            Send us a message
          </div>
          <div className="text-center sm:text-base text-sm mt-4 sm:text-textGray text-teal">
            If your query is not answered above - please send us a message via
            the form below.
          </div>
        </div>

        <div className="h-full">
          <SendUsMessage />
        </div>
      </div>
    </>
  );
};

export default GeneralFAQs;
