/** @format */

import React from "react";
import Button from "./Button";

const HeroSection = ({ scrollElement, letterTitle, bannerImage }) => {
  const letterName = letterTitle.split("\n");
  const nameInitial = letterName[0];
  const nameEnd = letterName[1];

  const handleButtonClick = () => {
    if (scrollElement.current) {
      scrollElement.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between md:gap-20   items-center  mt-16 mb-24">
      <div className="w-full   xl:w-3/5 mb-8 md:mb-0">
        <img src={bannerImage} alt="bannerImage" className="" />
      </div>
      <div className="  pr-4 text-center md:text-left ">
        <h1 className=" font-semibold  lg:text-4xl xl:text-6xl text-3xl   text-wrap ">
          {nameInitial} <br /> {nameEnd} Section
        </h1>
        <p className="xl:py-8 py-4  w-full xl:w-4/5 text-textGray  ">
          <span className="font-bold text-black"> Same-day</span> medical
          certificates and referral letters. General medical letters to access
          private specialist care.
        </p>
        <div className="flex justify-center md:justify-start">
          <Button
            buttonText="View Medical Letters"
            buttonStyle="bg-teal text-white px-6 py-4 text-sm xl:text-lg  xl:px-8 xl:py-5 rounded-xl cursor-pointer"
            onButtonClick={handleButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
