/** @format */

const initialState = {
  firstName: "",
  secondName: "",
  phoneNumber: "",
  emailAddress: "",
  address: "",
  country: "",
  city: "",
  zipCode: "",
  dateOfBirth: "",
  Gender: "",
  photoId: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
