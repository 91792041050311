/** @format */

import React, { useState } from "react";
import Button from "./Button";

const SendUsMessageForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      } else if (
        (key === "firstName" || key === "lastName") &&
        (formData[key].length < 3 || formData[key].length > 20)
      ) {
        newErrors[key] = "Name must be between 3 and 20 characters";
      } else if (key === "phoneNumber" && formData[key].length !== 11) {
        newErrors[key] = "Phone number must be exactly 11 digits";
      } else if (key === "email" && !emailRegex.test(formData[key])) {
        newErrors[key] = "Please enter a valid email address";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
    }
  };

  return (
    <>
      <div className="p-6 md:p-10 md:pt-16 flex flex-col gap-8 md:gap-12">
        <div className="flex flex-col md:flex-row gap-8 md:gap-16">
          <div className="flex flex-col text-left w-full md:w-1/2">
            <small className="text-textGray">First Name</small>
            <input
              className=" w-full p-2 pr-2 pl-2 sm:pl-0 sm:mt-0 mt-2 focus:outline-none border  border-gray-200 sm:border-b sm:border-t-0 sm:border-r-0 sm:border-l-0 rounded-lg sm:rounded-none "
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              minLength={3}
              maxLength={20}
            />
            {errors.firstName && (
              <small className="text-red-500">{errors.firstName}</small>
            )}
          </div>
          <div className="flex flex-col text-left w-full md:w-1/2">
            <small className="text-textGray">Last Name</small>
            <input
              className=" w-full p-2 pr-2 pl-2 sm:pl-0 sm:mt-0 mt-2 focus:outline-none border border-gray-200  sm:border-b sm:border-t-0 sm:border-r-0 sm:border-l-0 rounded-lg sm:rounded-none"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              minLength={3}
              maxLength={20}
            />
            {errors.lastName && (
              <small className="text-red-500">{errors.lastName}</small>
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 md:gap-16">
          <div className="flex flex-col text-left w-full md:w-1/2">
            <small className="text-textGray">Email</small>
            <input
              className=" w-full p-2 pr-2 pl-2 sm:pl-0 sm:mt-0 mt-2 focus:outline-none border border-gray-200  sm:border-b sm:border-t-0 sm:border-r-0 sm:border-l-0 rounded-lg sm:rounded-none"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <small className="text-red-500">{errors.email}</small>
            )}
          </div>
          <div className="flex flex-col text-left w-full md:w-1/2">
            <small className="text-textGray">Phone Number</small>
            <input
              className=" w-full p-2 pr-2 pl-2 sm:pl-0 sm:mt-0 mt-2 focus:outline-none border border-gray-200 sm:border-b sm:border-t-0 sm:border-r-0 sm:border-l-0 rounded-lg sm:rounded-none"
              type="number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              maxLength={11}
            />
            {errors.phoneNumber && (
              <small className="text-red-500">{errors.phoneNumber}</small>
            )}
          </div>
        </div>
        <div>
          <div className="font-semibold text-left">Select Subject?</div>
          <div className="flex flex-wrap gap-4 mt-4">
            {["general", "support", "sales", "other"].map((value) => (
              <div key={value} className="flex items-center">
                <input
                  type="radio"
                  name="subject"
                  value={value}
                  checked={formData.subject === value}
                  onChange={handleInputChange}
                  className="appearance-none w-4 h-4 border border-gray-300 rounded-full checked:bg-teal checked:border-none focus:outline-none cursor-pointer"
                />
                <label className="ml-2 text-sm">
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </label>
              </div>
            ))}
          </div>
          {errors.subject && (
            <small className="text-red-500 text-left">{errors.subject}</small>
          )}
        </div>
        <div className="flex flex-col">
          <small className="text-textGray text-left">Message</small>
          <textarea
            className="border-b-2 mt-2 focus:outline-none h-24 resize-none"
            placeholder="Write your message.."
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
          {errors.message && (
            <small className="text-red-500 text-left mt-1">
              {errors.message}
            </small>
          )}
        </div>
        <div className="sm:flex sm:justify-end w-full">
          <Button
            buttonText="Send Message"
            buttonStyle="rounded-lg bg-teal px-8 md:px-10 shadow-lg py-3 md:py-4 text-white cursor-pointer"
            onButtonClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default SendUsMessageForm;
