/** @format */

import React from "react";
import StepsTiles from "./StepsTiles";

const VerificationSteps = ({ isProductDetail }) => {
  const steps = [
    {
      text: "Upload Medical Evidence",
      subText: isProductDetail
        ? "Upload by 9pm for same-day service. This should be a 30 second — 1 minute video."
        : "(By 9PM for same-day service)",
    },
    {
      text: "Doctor Validates Evidence",
      subText: isProductDetail
        ? "Our doctors will look over the evidence you provide"
        : "",
    },
    {
      text: isProductDetail
        ? "Recieve Letter, Same Day"
        : "Receive Your Document The Same Day",
      subText: isProductDetail
        ? "You'll receive an email with your letter & a QR code so they can be verified and accepted worldwide."
        : "",
    },
  ];
  return (
    <>
      <div className="h-full">
        <div className="xl:text-4xl flex justify-center   ">
          {isProductDetail ? (
            <div className="xl:text-4xl lg:text-3xl text-2xl text-center mb-16 mt-4">
              How to get an Allergy Medical Certificate
            </div>
          ) : (
            <div className="xl:w-2/3 lg:w-3/4 sm:mb-24 mb-12 text-xl font-medium sm:font-semibold sm:text-4xl text-center sm:px-0 px-2   ">
              <span className="text-teal"> UK NHS GP Verified: </span>
              45,000+ Medical Certificates – No Appointment Required
            </div>
          )}
        </div>
        <div className={`${isProductDetail ? "xl:mx-0 mx-6" : ""}`}>
          <StepsTiles steps={steps} isProductDetail={isProductDetail} />
        </div>
      </div>
    </>
  );
};

export default VerificationSteps;
