/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
const DropDownMenu = ({ menuItems, menuItemId }) => {
  const navigate = useNavigate();
  const handleNavigation = (letterId) => {
    navigate(`/letter-detail/${letterId}`);
  };
  return (
    <div className="flex flex-col  w-64 bg-white text-black z-10 rounded-md border text-left  py-4 ">
      {menuItems.map((item, index) => (
        <div
          key={index}
          className="p-2 hover:bg-teal hover:text-white cursor-pointer font-thin text-sm  "
          onClick={() => handleNavigation(menuItemId[index])}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default DropDownMenu;
