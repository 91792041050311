/** @format */

import React, { useState, useEffect, useRef } from "react";
import DropDownMenu from "./DropDownMenu";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLettersBySearchRequest } from "../store/actions/medicalLetterActions";

const NavHeader = ({ letters, isLoading }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileSearch, setIsMobileSearch] = useState(false);

  const filteredSuggestions = useSelector(
    (state) => state?.medicalLetter?.searchLetters
  );
  const loadingSuggestion = useSelector(
    (state) => state?.medicalLetter?.loadingSearch
  );

  useEffect(() => {
    const handleClickOutsideDesktop = (event) => {
      const searchInput = document.getElementById("searchInput");
      const searchIcon = document.getElementById("searchIcon");

      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        searchIcon &&
        !searchIcon.contains(event.target)
      ) {
        setIsSearch(false);
      }
    };

    const handleClickOutsideMobile = (event) => {
      const mobileSearchInput = document.getElementById("mobileSearchInput");
      const mobileSearchIcon = document.getElementById("mobileSearchIcon");

      if (
        mobileSearchInput &&
        !mobileSearchInput.contains(event.target) &&
        mobileSearchIcon &&
        !mobileSearchIcon.contains(event.target)
      ) {
        setIsMobileSearch(false);
      }
    };

    if (isSearch) {
      document.addEventListener("mousedown", handleClickOutsideDesktop);
    }
    if (isMobileSearch) {
      document.addEventListener("mousedown", handleClickOutsideMobile);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDesktop);
      document.removeEventListener("mousedown", handleClickOutsideMobile);
    };
  }, [isSearch, isMobileSearch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchField.length > 0) {
        dispatch(fetchLettersBySearchRequest(searchField));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchField, dispatch]);

  const handleNavigation = (letterId) => {
    navigate(`/letter-detail/${letterId}`);
  };

  const toggleMobileSearch = (query) => {
    setIsMenuOpen(false);
    if (isMobileSearch) {
      if (query?.length !== 0) navigate(`/search/${query}`);

      setIsMobileSearch(false);
      setSearchField("");
    } else {
      setIsMobileSearch(true);
    }
  };

  const handleSearchFieldChange = (e) => {
    const value = e.target.value;
    setSearchField(value);
  };

  const navigateToSearch = (query) => {
    if (isSearch) {
      if (query.length !== 0) navigate(`/search/${query}`);
    } else {
      setIsSearch(true);
    }
  };

  const handleSuggestionClick = (suggestionId, suggestionName) => {
    navigate(`/letter-detail/${suggestionId}`);
    setSearchField(suggestionName);
    setIsSearch(false);
    setIsMobileSearch(false);
    setSearchField("");
  };

  const toggleMenu = (menu) => {
    setIsMobileSearch(false);
    setIsMenuOpen(!isMenuOpen);
    setActiveMenu(activeMenu === menu ? -1 : menu);
  };

  const getRouteFromMenu = (menu) => {
    switch (menu) {
      case "General Referrals":
        return "/";
      case "Recovery Certificates":
        return "/recovery-certificates";
      case "Sickness Letters":
        return "/sickness-letters";
      case "Travel & Holiday":
        return "/travel-and-holiday";
      case "Visa Medicals":
        return "/visa-medicals";
      default:
        return "/";
    }
  };

  return (
    <nav className="bg-white flex items-center justify-between text-black h-24 shadow-lg shadow-gray-100 px-8 font-sans relative">
      <div className="sm:w-1/4 w-2/3">
        <img
          src="/assets/nav_logo.png"
          alt="Logo"
          onClick={() => navigate("/")}
          className="cursor-pointer"
        />
      </div>
      <div className="flex items-center w-5/6 justify-end xl:justify-between">
        {/* 
      ----------------------------
      System Screen Nav bar items
      ----------------------------
      */}
        <div className="hidden xl:flex gap-16 justify-between">
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="h-6 w-40 bg-gray-300 rounded-md animate-pulse"
                ></div>
              ))
            : letters?.map((letterCategory, key) => (
                <div
                  key={key}
                  className="relative"
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <NavLink
                    to={getRouteFromMenu(letterCategory.name)}
                    className={({ isActive }) =>
                      `relative flex items-center font-thin
                    ${isActive ? "text-teal cursor-pointer" : "text-gray-600"}`
                    }
                    onClick={() => setActiveMenu(letterCategory.id)}
                  >
                    <span onMouseEnter={() => setHoveredIndex(key)}>
                      {" "}
                      {letterCategory.name}
                    </span>
                    <span
                      className="material-icons w-5 pl-2"
                      onClick={() => setHoveredIndex(key)}
                    >
                      keyboard_arrow_down_outlined
                    </span>
                  </NavLink>
                  {hoveredIndex === key && (
                    <div className="absolute top-full left-0 z-10 drop-shadow-xl">
                      <DropDownMenu
                        menuItems={letterCategory.medical_letters.map(
                          (medicalLetter) => medicalLetter.name
                        )}
                        menuItemId={letterCategory.medical_letters.map(
                          (medicalLetter) => medicalLetter.id
                        )}
                      />
                    </div>
                  )}
                </div>
              ))}
        </div>

        {/* 
-------------------------
System Screens Search
-------------------------
*/}

        <div className=" items-center gap-2  hidden xl:flex " id="searchInput">
          <input
            type="text"
            value={searchField}
            disabled={!isSearch}
            onChange={handleSearchFieldChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigateToSearch(searchField);
              }
            }}
            className={`transition-all duration-300 ease-in-out rounded-lg outline-none z-20${
              isSearch
                ? " px-2 py-1 w-64 border border-gray-200 "
                : " border border-gray-100 w-0.5 "
            }`}
          />
          {isSearch && searchField?.length > 0 && (
            <div className="absolute top-14 mt-2 bg-white border-t border-gray-200 w-64 rounded-lg shadow-lg z-50">
              {loadingSuggestion ? (
                <div className="h-32 flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-200 fill-teal"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : filteredSuggestions?.length > 0 ? (
                <>
                  {filteredSuggestions.slice(0, 5).map((suggestion, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-teal hover:text-white cursor-pointer text-left text-sm capitalize z-50"
                      onClick={() =>
                        handleSuggestionClick(suggestion?.id, suggestion?.name)
                      }
                    >
                      {suggestion?.name}
                    </div>
                  ))}
                  <div
                    className="px-4 py-2 text-teal hover:bg-teal hover:text-white cursor-pointer text-left text-sm capitalize mb-4"
                    onClick={() => navigateToSearch(searchField)}
                  >
                    See All Results
                  </div>
                </>
              ) : (
                <div className="px-4 py-2 text-gray-500 text-left text-sm">
                  No results found
                </div>
              )}
            </div>
          )}
          <div
            id="searchIcon"
            className={`material-icons w-10 cursor-pointer xl:flex hidden ${
              isSearch ? "text-teal" : "text-teal"
            }`}
            onClick={() => navigateToSearch(searchField)}
          >
            search_outlined
          </div>
        </div>

        {/* 
-------------------------
Native Screens Search
-------------------------
*/}
        <div className="xl:hidden">
          <div className="flex items-center " id="mobileSearchInput">
            <input
              type="text"
              value={searchField}
              disabled={!isMobileSearch}
              onChange={handleSearchFieldChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigateToSearch(searchField);
                }
              }}
              className={`transition-all duration-300 ease-in-out rounded-lg outline-none z-20 ${
                isMobileSearch
                  ? " px-2 py-1 w-64 border border-gray-200 "
                  : " border border-gray-100 w-0.5 "
              }`}
            />
            {isMobileSearch && searchField && (
              <div className="absolute top-14 mt-2 bg-white border-t border-gray-200 w-64 rounded-lg shadow-lg z-50">
                {loadingSuggestion ? (
                  <div className="h-32 flex justify-center items-center">
                    <svg
                      aria-hidden="true"
                      className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-200 fill-teal"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : filteredSuggestions?.length > 0 ? (
                  <>
                    {filteredSuggestions
                      .slice(0, 5)
                      .map((suggestion, index) => (
                        <div
                          key={index}
                          className="px-4 py-2 hover:bg-teal hover:text-white cursor-pointer text-left text-sm capitalize"
                          onClick={() =>
                            handleSuggestionClick(
                              suggestion?.id,
                              suggestion?.name
                            )
                          }
                        >
                          {suggestion?.name}
                        </div>
                      ))}
                    <div
                      className="px-4 py-2 text-teal hover:bg-teal hover:text-white cursor-pointer text-left text-sm capitalize mb-4"
                      onClick={() => navigateToSearch(searchField)}
                    >
                      See All Results
                    </div>
                  </>
                ) : (
                  <div className="px-4 py-2 text-gray-500 text-left text-sm">
                    No results found
                  </div>
                )}
              </div>
            )}
            <div
              className={`material-icons w-10 cursor-pointer xl:hidden flex ${
                isMobileSearchOpen ? "text-teal" : "text-teal"
              }`}
              id="mobileSearchIcon"
              onClick={() => toggleMobileSearch(searchField)}
            >
              search_outlined
            </div>
            <button
              onClick={toggleMenu}
              className="text-teal flex items-center"
            >
              <span className="material-icons">menu</span>
            </button>
          </div>
        </div>
      </div>

      {/* 
      ----------------------------
      Native Screen Nav bar items
      ----------------------------
      */}

      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-white shadow-lg z-50 xl:hidden">
          <ul className="py-4">
            {letters?.map((letterCategory, index) => (
              <li key={index} className="border-b">
                <div
                  className={`flex items-center justify-between px-6 py-3 text-gray-800 ${
                    activeMenu === letterCategory.name
                      ? "bg-teal text-white"
                      : ""
                  }`}
                >
                  <NavLink
                    onClick={() => toggleMenu(letterCategory.name)}
                    to={getRouteFromMenu(letterCategory.name)}
                    className={`${
                      activeMenu === letterCategory.name
                        ? "text-white"
                        : "text-gray-800"
                    }`}
                  >
                    {letterCategory.name}
                  </NavLink>
                  <button
                    className="focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveMenu(
                        activeMenu === letterCategory.name
                          ? -1
                          : letterCategory.name
                      );
                    }}
                  >
                    <span className="material-icons">
                      {activeMenu === letterCategory.name
                        ? "expand_less"
                        : "expand_more"}
                    </span>
                  </button>
                </div>
                {activeMenu === letterCategory.name && (
                  <ul className="pl-6 bg-teal-50">
                    {letterCategory.medical_letters.map((subMenu, subIndex) => (
                      <li key={subIndex} className="py-2 text-left px-4">
                        <div
                          className="text-gray-600 hover:text-teal"
                          onClick={() => {
                            handleNavigation(subMenu.id);
                            setIsMenuOpen(false);
                          }}
                        >
                          {subMenu.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavHeader;
