/** @format */

import React from "react";
import { useParams } from "react-router-dom";

const ThankYou = () => {
  const { orderId } = useParams();
  return (
    <div className=" h-svh flex justify-center items-center ">
      <div className="flex flex-col gap-2 justify-center items-center">
        <img
          src="/assets/Excellence.svg"
          alt="star"
          className="sm:w-1/5 w-1/6"
        />
        <div className="font-bold text-center sm:text-6xl text-2xl text-teal py-6">
          Thank you for your purchase
        </div>
        <div className="sm:text-base text-sm">
          Lorem ipsum dolor sit amit Lorem ipsum dolor sit amit
        </div>
        <div className="sm:text-base text-sm">Order {orderId}</div>
      </div>
    </div>
  );
};

export default ThankYou;
