/** @format */

import React from "react";
import TestimonialCard from "./TestimonialCard";

const Testimonials = () => {
  const testimonials = [
    {
      comment:
        "Dude, your stuff is the bomb! House rent is the real deal! I STRONGLY recommend house rent to EVERYONE interested in running a successful online business!",
      author: "Lana Bernier",
      designation: "Senior Paradigm Strategist",
    },
    {
      comment:
        "Dude, your stuff is the bomb! House rent is the real deal! I STRONGLY recommend house rent to EVERYONE interested in running a successful online business!",
      author: "Lana Bernier",
      designation: "Senior Paradigm Strategist",
    },
    {
      comment:
        "Dude, your stuff is the bomb! House rent is the real deal! I STRONGLY recommend house rent to EVERYONE interested in running a successful online business!",
      author: "Lana Bernier",
      designation: "Senior Paradigm Strategist",
    },
  ];
  return (
    <>
      <div className=" pt-12 pb-8">
        <div className=" xl:text-4xl lg:text-3xl text-2xl font-semibold ">
          The future of same day medical letters
        </div>
        <div className="text-teal pt-2 xl:text-base text-sm">
          General medical letters to access private specialist care.
        </div>
      </div>
      <TestimonialCard testimonials={testimonials} />
    </>
  );
};

export default Testimonials;
