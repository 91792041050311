/** @format */

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = ({ letters }) => {
  const navigate = useNavigate();

  const getRouteFromMenu = (menu) => {
    switch (menu) {
      case "General Referrals":
        return "/";
      case "Recovery Certificates":
        return "/recovery-certificates";
      case "Sickness Letters":
        return "/sickness-letters";
      case "Travel & Holiday":
        return "/travel-and-holiday";
      case "Visa Medicals":
        return "/visa-medicals";
      default:
        return "/";
    }
  };

  const RouteFunction = (path) => {
    navigate(path);
  };

  return (
    <div>
      <footer className="bg-teal text-white py-8">
        <div className="mx-4 xl:mx-32 flex flex-col items-center md:flex-row md:justify-between md:gap-16 md:px-12 xl:gap-0 xl:px-0 sm:items-start">
          <div className="mb-6 md:mb-0">
            <img
              src="/assets/nav_logo.png"
              alt="Logo"
              onClick={() => navigate("/")}
              className="cursor-pointer w-48 sm:w-60 md:w-72"
            />
          </div>

          <div className="flex flex-col sm:flex-row md:flex-row md:gap-32 gap-12 text-left">
            <div>
              <h3 className="text-lg mb-2">Frequent Letters</h3>
              <ul className="space-y-1 text-gray-200">
                <li>
                  <a href="#" className="hover:underline">
                    Allergy Letters
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Sick Letters
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Travel Letter
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Vaccine Exemption
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg mb-2">Letters</h3>
              <ul className="space-y-1 text-gray-200 flex flex-col">
                {letters?.map((letterCategory, key) => (
                  <NavLink
                    to={getRouteFromMenu(letterCategory.name)}
                    className="cursor-pointer"
                    key={key}
                  >
                    <span onClick={() => RouteFunction("/")}>
                      {letterCategory.name}
                    </span>
                  </NavLink>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-lg mb-2">Company</h3>
              <ul className="space-y-1 text-gray-200">
                <li>
                  <a href="#" className="hover:underline">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    How It Works
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-8 lg:px-32 py-4 bg-[#CAFFFB] text-xs sm:text-sm font-light text-gray-700">
        <div className="text-center sm:text-left mb-2 sm:mb-0">
          Copyright © 2024 Rushden Medical Centre. All Rights Reserved.
        </div>
        <div className="flex flex-wrap justify-center sm:justify-end gap-2 sm:gap-4 underline">
          <span className="cursor-pointer">Accessibility</span>
          <span
            className="cursor-pointer"
            onClick={() => RouteFunction("/terms-and-conditions")}
          >
            Terms & Conditions
          </span>
          <span
            className="cursor-pointer"
            onClick={() => RouteFunction("/privacy-policy")}
          >
            Privacy Policy
          </span>
          <span
            className="cursor-pointer"
            onClick={() => RouteFunction("/general-faqs")}
          >
            Contact Us
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
