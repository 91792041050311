/** @format */

import React, { useState } from "react";
import Button from "./Button";

const UploadAddFileModal = ({
  onClose,
  onVideoCamAccess,
  onPictureCamAccess,
  onBrowseFile,
  isPicture,
  maxFileSize,
}) => {
  const MAX_FILE_SIZE = maxFileSize * 1024 * 1024;
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(`File size exceeds ${maxFileSize} MB.`);
        return;
      }
      setErrorMessage("");
      onBrowseFile(event);
    }
  };

  return (
    <>
      <style>
        {` input[type="file"] {
            display: none;
          }
            .custom-file-label {
            display: inline-block;}
`}
      </style>
      <div className=" xl:w-1/4 w-3/4 bg-white flex flex-col gap-4  px-6  pb-6 pt-3  rounded-xl shadow-lg  ">
        <div
          className="material-icons text-end text-red cursor-pointer"
          onClick={onClose}
        >
          cancel
        </div>

        <label
          htmlFor="file-upload"
          className="flex bg-teal rounded-lg justify-center md:mx-24 items-center gap-2 py-2 cursor-pointer "
        >
          <div className="material-icons w-5 text-white">
            file_upload_outlined
          </div>
          <div className="flex">
            <Button
              buttonText="Upload from files"
              buttonStyle="text-lg text-white"
            />
          </div>
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          accept={isPicture ? ".doc, .docx, .pdf,  image/*" : "video/*"}
        />

        {errorMessage && (
          <div className="text-red-500 mt-2">{errorMessage}</div>
        )}
      </div>
    </>
  );
};

export default UploadAddFileModal;
