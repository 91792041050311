/** @format */

import { all } from "redux-saga/effects";
import { watchUserData } from "./userSaga";
import {
  watchCheckout,
  watchFetchLetter,
  watchFetchLetters,
  watchFetchLettersByCategory,
  watchFetchLettersBySearch,
  watchPaymentStatus,
  watchProcessPayment,
} from "./medicalLetterSaga";

export default function* rootSaga() {
  yield all([
    watchUserData(),
    watchFetchLetters(),
    watchFetchLetter(),
    watchCheckout(),
    watchFetchLettersByCategory(),
    watchFetchLettersBySearch(),
    watchProcessPayment(),
    watchPaymentStatus(),
  ]);
}
