/** @format */
import React, { useEffect, useState } from "react";
import ProductDetailsCard from "../../components/ProductDetailsCard";
import Tabs from "../../components/Tabs";
import VerificationSteps from "../../components/VerificationSteps";
import CheckMarkCard from "../../components/CheckMarkCard";
import Testimonials from "../../components/Testimonials";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLetterRequest } from "../../store/actions/medicalLetterActions";

const ProductDetails = () => {
  const { letterId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLetterRequest(letterId));
  }, [dispatch, letterId]);

  const letter = useSelector((state) => state.medicalLetter.currentLetter);
  const loading = useSelector((state) => state.medicalLetter.loading);

  const tabs = ["Description", "How It Works"];
  const [activeIndex, setActiveIndex] = useState(0);
  const handleActiveTabsChange = (index) => {
    setActiveIndex(index);
  };

  const needToSupply = [
    "Your name and date of birth.",
    "The type of allergy and allergens that can cause a serious allergic reaction or anaphylaxis. ",
    "Medications that you require to carry with you, in case of exposure.",
    "Signature and authorisation by one of our GMC-registered UK doctors.",
    "Contact details of Medical Letter with a QR code enabling a verification check.",
  ];

  return (
    <>
      <div className=" xl:my-20 my-8">
        <div className="xl:mx-44">
          <ProductDetailsCard letter={letter} loading={loading} />
          <div className="my-16 mx-4 xl:mx-0">
            <Tabs tabs={tabs} handleTabChange={handleActiveTabsChange} />
            {activeIndex === 0 ? (
              <div className="flex flex-col items-center justify-center my-12">
                <div className="xl:text-4xl sm:text-3xl text-2xl text-center my-2">
                  {letter?.name}
                </div>
                <div className=" leading-10 w-3/4 text-center sm:py-10 py-4 sm:text-base text-sm">
                  If you or your child has a severe allergy and require a
                  medical certificate to simply confirm the exact type of
                  allergy and the medications that you require to have on
                  standby in case of exposure, we are here to help. Allergies
                  are very common and for some, can prove fatal if they suffer
                  from a severe anaphylactic reaction and do not have access to
                  emergency medical treatments. Some of the most common type of
                  allergies include medications, food allergens [ie nuts, milk,
                  gluten], pollen, dust mites, latex, animal dander and insect
                  stings. Medications that allergy patients will often have on
                  standby may include antihistamines and intramuscular
                  adrenaline. Our doctors can provide you with
                  a same-day medical certificate that will confirm the exact
                  allergen, the signs of the allergic reaction, what you need to
                  avoid, plus the medications you require to have on standby. No
                  appointment is needed — simply apply online.
                </div>
              </div>
            ) : (
              <div className="my-12">
                <VerificationSteps isProductDetail={true} />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center ">
          <div className="xl:text-4xl sm:text-3xl  text-2xl text-center my-2">
            What Will I Need To Supply?
          </div>
          <div className="  text-teal  text-center font-thin   xl:w-1/2 w-3/4 xl:my-0 my-4">
            You will receive a verifiable digital PDF medical letter signed by a
            medical professional and sent directly to your mobile, containing
            the following details:
          </div>
          <div className="mt-6">
            <div className="flex sm:flex-row flex-col sm:flex-wrap xl:gap-x-16 sm:gap-x-6 gap-y-10 sm:justify-center  items-center sm:mx-0  mx-8 ">
              <CheckMarkCard items={needToSupply} />
            </div>
          </div>
        </div>

        <div className="md:px-16 lg:px-32 px-8 pb-16 md:mt-24 ">
          <Testimonials />
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
