/** @format */

export const FETCH_LETTERS_REQUEST = "FETCH_LETTERS_REQUEST";
export const FETCH_LETTERS_SUCCESS = "FETCH_LETTERS_SUCCESS";
export const FETCH_LETTERS_FAILURE = "FETCH_LETTERS_FAILURE";

export const FETCH_LETTER_REQUEST = "FETCH_LETTER_REQUEST";
export const FETCH_LETTER_SUCCESS = "FETCH_LETTER_SUCCESS";
export const FETCH_LETTER_FAILURE = "FETCH_LETTER_FAILURE";

export const PROCESS_PAYMENT_REQUEST = "PROCESS_PAYMENT_REQUEST";
export const PROCESS_PAYMENT_SUCCESS = "PROCESS_PAYMENT_SUCCESS";
export const PROCESS_PAYMENT_FAILURE = "PROCESS_PAYMENT_FAILURE";

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const FETCH_LETTERS_BY_CATEGORY_REQUEST =
  "FETCH_LETTERS_BY_CATEGORY_REQUEST";
export const FETCH_LETTERS_BY_CATEGORY_SUCCESS =
  "FETCH_LETTERS_BY_CATEGORY_SUCCESS";
export const FETCH_LETTERS_BY_CATEGORY_FAILURE =
  "FETCH_LETTERS_BY_CATEGORY_FAILURE";

export const FETCH_LETTERS_BY_SEARCH_REQUEST =
  "FETCH_LETTERS_BY_SEARCH_REQUEST";
export const FETCH_LETTERS_BY_SEARCH_SUCCESS =
  "FETCH_LETTERS_BY_SEARCH_SUCCESS";
export const FETCH_LETTERS_BY_SEARCH_FAILURE =
  "FETCH_LETTERS_BY_SEARCH_FAILURE";

export const PAYMENT_STATUS_REQUEST = "PAYMENT_STATUS_REQUEST";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAILURE = "PAYMENT_STATUS_FAILURE";

export const fetchLettersRequest = () => ({ type: FETCH_LETTERS_REQUEST });
export const fetchLettersSuccess = (data) => ({
  type: FETCH_LETTERS_SUCCESS,
  payload: data,
});
export const fetchLettersFailure = (error) => ({
  type: FETCH_LETTERS_FAILURE,
  payload: error,
});

export const fetchLetterRequest = (payload) => ({
  type: FETCH_LETTER_REQUEST,
  payload,
});
export const fetchLetterSuccess = (data) => ({
  type: FETCH_LETTER_SUCCESS,
  payload: data,
});
export const fetchLetterFailure = (error) => ({
  type: FETCH_LETTER_FAILURE,
  payload: error,
});

export const processPaymentRequest = (payload) => ({
  type: PROCESS_PAYMENT_REQUEST,
  payload,
});

export const processPaymentSuccess = (data) => ({
  type: PROCESS_PAYMENT_SUCCESS,
  payload: data,
});

export const processPaymentFailure = (error) => ({
  type: PROCESS_PAYMENT_FAILURE,
  payload: error,
});

export const checkoutRequest = (payload) => ({
  type: CHECKOUT_REQUEST,
  payload,
});
export const checkoutSuccess = (data) => ({
  type: CHECKOUT_SUCCESS,
  payload: data,
});
export const checkoutFailure = (error) => ({
  type: CHECKOUT_FAILURE,
  payload: error,
});

export const paymentStatusRequest = (payload) => ({
  type: PAYMENT_STATUS_REQUEST,
  payload,
});
export const paymentStatusSuccess = (status) => ({
  type: PAYMENT_STATUS_SUCCESS,
  payload: status,
});
export const paymentStatusFailure = (error) => ({
  type: PAYMENT_STATUS_FAILURE,
  payload: error,
});

export const fetchLettersByCategoryRequest = (category) => ({
  type: FETCH_LETTERS_BY_CATEGORY_REQUEST,
  payload: category,
});
export const fetchLettersByCategorySuccess = (data) => ({
  type: FETCH_LETTERS_BY_CATEGORY_SUCCESS,
  payload: data,
});
export const fetchLettersByCategoryFailure = (error) => ({
  type: FETCH_LETTERS_BY_CATEGORY_FAILURE,
  payload: error,
});

export const fetchLettersBySearchRequest = (searchTerm) => ({
  type: FETCH_LETTERS_BY_SEARCH_REQUEST,
  payload: searchTerm,
});
export const fetchLettersBySearchSuccess = (data) => ({
  type: FETCH_LETTERS_BY_SEARCH_SUCCESS,
  payload: data,
});
export const fetchLettersBySearchFailure = (error) => ({
  type: FETCH_LETTERS_BY_SEARCH_FAILURE,
  payload: error,
});
