/** @format */

import React, { useEffect, useState } from "react";
import Button from "./Button";
import Select from "./Select";

const PatientDetailsForm = ({ handleButtonClick, user }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    city: "",
    country: "",
    zipPostalCode: "",
    dateOfBirth: "",
    gender: "",
    marketingConsent: false,
  });
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    city: "",
    country: "",
    zipPostalCode: "",
    dateOfBirth: "",
    gender: "",
  });

  useEffect(() => {
    if (user) {
      setUserInfo({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phoneNumber: user.phoneNumber || "",
        emailAddress: user.emailAddress || "",
        address: user.address || "",
        city: user.city || "",
        country: user.country || "",
        zipPostalCode: user.zipPostalCode || "",
        dateOfBirth: user.dateOfBirth || "",
        gender: user.gender || "",
        marketingConsent: user.marketingConsent || false,
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleBackButtonClick = () => {
    handleButtonClick(1);
  };

  const handleNextButtonClick = () => {
    const newErrors = {};
    let hasError = false;

    if (!userInfo.firstName) {
      newErrors.firstName = "First name is required";
      hasError = true;
    }
    if (!userInfo.lastName) {
      newErrors.lastName = "Last name is required";
      hasError = true;
    }
    if (!userInfo.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      hasError = true;
    }
    if (!userInfo.emailAddress) {
      newErrors.emailAddress = "Email address is required";
      hasError = true;
    }
    if (!userInfo.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (!userInfo.city) {
      newErrors.city = "City is required";
      hasError = true;
    }
    if (!userInfo.country) {
      newErrors.country = "Country is required";
      hasError = true;
    }
    if (!userInfo.zipPostalCode) {
      newErrors.zipPostalCode = "Zip/Postal code is required";
      hasError = true;
    }
    if (!userInfo.dateOfBirth) {
      newErrors.dateOfBirth = "Date of birth is required";
      hasError = true;
    }
    if (!userInfo.gender) {
      newErrors.gender = "Gender is required";
      hasError = true;
    }
    if (!file) {
      newErrors.photoId = "Please upload a photo ID";
      hasError = true;
    }

    setErrors(newErrors);

    if (!hasError) {
      handleButtonClick(3, userInfo, file);
    }
  };
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileUrl, setFileUrl] = useState();
  function handleChange(e) {
    if (e.target?.files?.length) {
      setFileName(e.target.files[0].name);
      setFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
      setErrors((prevErrors) => ({ ...prevErrors, photoId: "" }));
    }
  }

  return (
    <>
      <style>
        {`
           input[type="file"] {
            display: none;
          }

          .custom-file-label {
            display: inline-block;
            padding: 100px;
            cursor: pointer;
            background-image: url('/assets/upload_photo.svg');
            background-size: cover;
            background-position: center;
          }
          input[type='checkbox'] {
            accent-color: teal;
          }
            input[type='date'] {
            color: teal;
          }
            
        
        `}
      </style>
      <div className="w-full py-10 ">
        <div className=" pb-5 px-6">
          <h2 className="hidden sm:block text-4xl font-bold  text-teal text-left  ">
            Who is this Medical Certificate for?
          </h2>
          <div className="block sm:hidden">
            <h2 className="text-3xl font-bold  text-teal sm:text-left text-center  ">
              Patient Details
            </h2>
            <div className="sm:text-lg text-sm   text-textGray sm:text-left text-center sm:mt-0 mt-4 ">
              This Allergy Medical Certificate will Confirm:
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col items-center pt-4">
          {file ? (
            <label
              className="flex sm:flex-row flex-col sm:gap-0 gap-6 items-center"
              htmlFor="file-upload"
            >
              <img
                src={fileUrl}
                alt="uploaded-picture"
                className=" w-36 h-32  rounded-xl shadow-lg mx-6"
              />
              <div className="text-lg">
                {fileName?.length > 5
                  ? fileName.slice(0, 4) +
                    "..." +
                    fileName.slice(fileName.length - 4)
                  : fileName}
              </div>
            </label>
          ) : (
            <>
              <label
                className="custom-file-label"
                htmlFor="file-upload"
              ></label>
              <div className="flex flex-col gap-2 items-start justify-between">
                <div className="mb-4">
                  <div className="text-2xl">Upload the Photo ID</div>
                  <div className="text-gray-400 text-left">
                    Clear Image for verification.
                  </div>
                </div>
              </div>
            </>
          )}
          <input
            id="file-upload"
            type="file"
            onChange={handleChange}
            accept="image/*"
          />
        </div>
        {errors.photoId && (
          <div className="text-red-500 text-left mx-6">{errors.photoId}</div>
        )}
        <div className="px-6 flex flex-col gap-5 py-10">
          <div className="flex sm:flex-row flex-col gap-5 ">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">First Name</label>
              <input
                type="text"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="First Name"
              />
              {errors.firstName && (
                <div className="text-red-500 text-left">{errors.firstName}</div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Last Name"
              />
              {errors.lastName && (
                <div className="text-red-500 text-left">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-5 ">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={userInfo.phoneNumber}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Phone Number"
              />
              {errors.phoneNumber && (
                <div className="text-red-500 text-left">
                  {errors.phoneNumber}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Email Address</label>
              <input
                type="text"
                name="emailAddress"
                value={userInfo.emailAddress}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Email Address"
              />
              {errors.emailAddress && (
                <div className="text-red-500 text-left">
                  {errors.emailAddress}
                </div>
              )}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-5 ">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Address</label>
              <input
                type="text"
                name="address"
                value={userInfo.address}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Address"
              />
              {errors.address && (
                <div className="text-red-500 text-left">{errors.address}</div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">City</label>
              <input
                type="text"
                name="city"
                value={userInfo.city}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="City"
              />
              {errors.city && (
                <div className="text-red-500 text-left">{errors.city}</div>
              )}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-5 ">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Country</label>
              <input
                type="text"
                name="country"
                value={userInfo.country}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Country"
              />
              {errors.country && (
                <div className="text-red-500 text-left">{errors.country}</div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Zip/Postal Code</label>
              <input
                type="text"
                name="zipPostalCode"
                value={userInfo.zipPostalCode}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Zip/Postal Code"
              />
              {errors.zipPostalCode && (
                <div className="text-red-500 text-left">
                  {errors.zipPostalCode}
                </div>
              )}
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-5 ">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                value={userInfo.dateOfBirth}
                onChange={handleInputChange}
                className="border border-gray-200 p-2  rounded-lg bg-white w-full"
                placeholder="Date of Birth"
              />
              {errors.dateOfBirth && (
                <div className="text-red-500 text-left">
                  {errors.dateOfBirth}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <label className="text-left">Gender</label>
              <Select
                options={genderOptions}
                value={
                  genderOptions.find(
                    (option) => option.value === userInfo.gender
                  ) || null
                }
                onChange={(selectedOption) =>
                  handleInputChange({
                    target: { name: "gender", value: selectedOption.value },
                  })
                }
              />
              {errors.gender && (
                <div className="text-red-500 text-left">{errors.gender}</div>
              )}
            </div>
          </div>
          <div className="flex gap-2 ">
            <input
              type="checkbox"
              name="marketingConsent"
              checked={userInfo.marketingConsent}
              onChange={handleInputChange}
              className="cursor-pointer"
            />
            <label className="text-left h-6 text-gray-500 text-wrap ">
              We would like to keep you up to date with products and services
              provided by ZoomDoc, our group of companies, and selected
              partners. If you are happy for us to do this by digital
              communications, please tick this box.
            </label>
          </div>
        </div>
      </div>
      <div className="mb-5 sm:mt-0 mt-16 flex sm:flex-row flex-col-reverse w-full gap-2 px-6">
        <Button
          buttonStyle={
            "py-3 bg-white text-teal border-2 border-teal rounded-xl  cursor-pointer sm:w-1/2"
          }
          buttonText="Back"
          onButtonClick={() => handleBackButtonClick(2)}
        />
        <Button
          buttonStyle={
            "py-3 bg-teal rounded-xl text-white cursor-pointer sm:w-1/2"
          }
          buttonText="Next"
          onButtonClick={handleNextButtonClick}
        />
      </div>
    </>
  );
};

export default PatientDetailsForm;
