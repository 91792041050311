/** @format */

import React, { useState, useEffect } from "react";
import Button from "./Button";
import UploadAddFileModal from "./UploadAddFileModal";

const MedicalDetailsForm = ({ handleButtonClick, handleMedicalEvidence }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalForField, setModalForField] = useState("");
  const [file, setFile] = useState({
    care_summary: "",
    additional_support: "",
    evidence_vedio: "",
  });
  const [fileName, setFileName] = useState({
    care_summary: "",
    additional_support: "",
    evidence_vedio: "",
  });
  const [errors, setErrors] = useState({
    care_summary: "",
    additional_support: "",
    evidence_vedio: "",
  });
  const [uploadProgress, setUploadProgress] = useState({
    care_summary: 0,
    additional_support: 0,
    evidence_vedio: 0,
  });

  useEffect(() => {
    setIsModalOpen(false);
  }, [file]);

  const handleBackButtonClick = () => {
    handleButtonClick(2);
  };

  const handleNextButtonClick = () => {
    const newErrors = {};

    if (!file.care_summary) {
      newErrors.care_summary = "Please upload a Summary of Care";
    }

    if (!file.additional_support) {
      newErrors.additional_support =
        "Please upload an Evidence for Additional Support ";
    }

    if (!file.evidence_vedio) {
      newErrors.evidence_vedio = "Please upload a video";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = new FormData();
      Object.keys(file).forEach((key) => {
        if (file[key]) {
          formData.append(key, file[key]);
        }
      });

      handleMedicalEvidence(formData);
      handleButtonClick(4);
    }
  };

  const handleOpenModal = (fieldValue) => {
    setModalForField(fieldValue);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.files[0].name;
    const fieldName = modalForField;

    if (fieldName) {
      setFile((prev) => ({ ...prev, [fieldName]: file }));
      setFileName((prev) => ({ ...prev, [fieldName]: name }));
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));

      const simulateUpload = (field) => {
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          setUploadProgress((prev) => ({ ...prev, [field]: progress }));
          if (progress >= 100) clearInterval(interval);
        }, 200);
      };

      simulateUpload(fieldName);
    }
  };

  const handleCancel = (fieldNumber) => {
    setFile((prev) => ({ ...prev, [fieldNumber]: "" }));
    setFileName((prev) => ({ ...prev, [fieldNumber]: "" }));
    setUploadProgress((prev) => ({ ...prev, [fieldNumber]: 0 }));
  };

  return (
    <>
      <div className="w-full py-10 px-6">
        <div className="pb-5">
          <h2 className="sm:text-4xl text-3xl font-bold text-teal sm:text-left text-center">
            Medical Details
          </h2>
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <UploadAddFileModal
                onClose={handleCloseModal}
                onBrowseFile={handleFileChange}
                isPicture={
                  modalForField.includes("evidence_vedio") ? false : true
                }
                maxFileSize={
                  modalForField.includes("evidence_vedio") ? 200 : 10
                }
              />
            </div>
          )}
        </div>

        <div className="flex gap-y-4 flex-col sm:flex-row  flex-wrap ">
          {["care_summary", "additional_support", "evidence_vedio"].map(
            (field) => (
              <div key={field} className="flex flex-col sm:w-1/2">
                <label className="text-left">
                  {field === "care_summary" && "Summary of Care"}
                  {field === "additional_support" && "Additional Support"}
                  {field === "evidence_vedio" && " Upload Video"}
                </label>
                {file[field] ? (
                  <span className="border border-gray-200 p-2 mt-2 sm:w-11/12 rounded-lg bg-white w-full text-teal text-left cursor-pointer flex items-end justify-between">
                    {fileName[field]?.length > 25
                      ? fileName[field]?.slice(0, 20) +
                        "....." +
                        fileName[field]?.slice(fileName[field]?.length - 20)
                      : fileName[field]}
                    <span
                      className="material-icons text-gray-800 text-xl"
                      onClick={() => handleCancel(field)}
                    >
                      cancel
                    </span>
                  </span>
                ) : (
                  <Button
                    buttonStyle="border border-gray-200 p-2 mt-2 rounded-lg bg-white sm:w-11/12  text-gray-400 text-left cursor-pointer"
                    buttonText="Upload"
                    onButtonClick={() => handleOpenModal(field)}
                  />
                )}
                {uploadProgress[field] > 0 && uploadProgress[field] < 100 && (
                  <div className="mt-2 sm:w-11/12">
                    <div className="h-4 bg-gray-200 rounded flex justify-between">
                      <div
                        className="h-full bg-teal rounded"
                        style={{ width: `${uploadProgress[field]}%` }}
                      ></div>
                      <span className="text-xs text-gray-500">
                        {uploadProgress[field]}%
                      </span>
                    </div>
                  </div>
                )}
                {errors[field] && (
                  <div className="text-red-500 text-left">{errors[field]}</div>
                )}
              </div>
            )
          )}
        </div>

        <div className="mt-10 flex sm:flex-row flex-col-reverse w-full gap-2">
          <Button
            buttonStyle="py-3 bg-white text-teal border-2 border-teal rounded-xl cursor-pointer sm:w-1/2"
            buttonText="Back"
            onButtonClick={handleBackButtonClick}
          />
          <Button
            buttonStyle="py-3 bg-teal rounded-xl text-white cursor-pointer sm:w-1/2"
            buttonText="Next"
            onButtonClick={handleNextButtonClick}
          />
        </div>
      </div>
    </>
  );
};

export default MedicalDetailsForm;
