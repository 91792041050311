/** @format */

import React from "react";

const StepsTiles = ({ steps, isProductDetail }) => {
  return (
    <>
      <div className="flex sm:flex-row sm:flex-wrap xl:flex-nowrap flex-col items-center sm:items-start justify-center gap-5">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`border border-teal-light   rounded-lg xl:w-1/3 sm:w-2/5 w-full pb-6 sm:pb-0 ${
              isProductDetail ? "sm:h-72" : "sm:h-64"
            } `}
          >
            <div
              className=" pt-10 pb-4 text-transparent stroke-teal  sm:text-8xl text-7xl font-bold"
              style={{
                WebkitTextStroke: "1px #14b8a6",
              }}
            >
              0{index + 1}
            </div>
            <div className=" font-light flex justify-center ">
              <div
                className={`sm:text-2xl  text-base ${
                  isProductDetail ? " w-3/4" : "xl:w-3/5"
                }  px-2 `}
              >
                {step.text}
              </div>
            </div>
            {step.subText && (
              <div
                className={` text-textGray font-extraligh xl:text-base text-sm ${
                  isProductDetail ? "pt-2 px-8" : ""
                }`}
              >
                {step.subText}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default StepsTiles;
